
  // IMPORTANT: This file was generated by tools/build.js
  // If you edit it, your changes will be overwritten once dev server restarts
  export default {
  "CONFIG_ID": "adobecommerce:staging",
  "CUSTOM_SUPPORT_URL": "https://experienceleague.adobe.com/home#support",
  "CUSTOM_VENDOR_CONSOLE_TITLE": false,
  "FEDERATED_AUTH": false,
  "VENDOR_CONNECT_CSP": "https://stg.magento.cloud/",
  "VENDOR_NAME": "magento",
  "WINDOW_TITLE": "Magento Cloud",
  "CUSTOM_APP_NAME": "Adobe Commerce Cloud",
  "CUSTOM_ASSETS_URL": "https://www.main-bvxea6i-q6jcg6bsdggvk.eu-3.platformsh.site/",
  "CUSTOM_BLACKFIRE_CARD_LANGUAGE": [
    "php",
    "python"
  ],
  "CUSTOM_BRANDING_MESSAGE": "Powered by Platform.sh",
  "CUSTOM_CLI_COMMAND": "magento-cloud",
  "CUSTOM_CONFIG_DIR_NAME": false,
  "CUSTOM_CONSOLE_ID": "magento",
  "CUSTOM_CONSOLE_TITLE": false,
  "CUSTOM_CSP": "https://stg.magento.cloud/",
  "CUSTOM_DEFAULT_BRANCH": "master",
  "CUSTOM_FEDERATED_AUTH_PROVIDERS": false,
  "CUSTOM_FOOTER_TEXT": false,
  "CUSTOM_LOGO_HEIGHT": 30,
  "CUSTOM_LOGO_WIDTH": false,
  "CUSTOM_PROFILING_LANGUAGE": [
    "php",
    "python",
    "golang"
  ],
  "CUSTOM_THEME": false,
  "CUSTOM_WINDOW_TITLE": "Magento Cloud",
  "CUSTOM_URL_BILLING": false,
  "CUSTOM_URL_CLI_DOWNLOAD": false,
  "CUSTOM_URL_DOCS": "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html",
  "CUSTOM_URL_DOCS_STATUS_CHECK": false,
  "CUSTOM_URL_PROJECT_CREATION": false,
  "CUSTOM_URL_SUBSCRIPTION_DELETE_REDIRECT": false,
  "CUSTOM_URL_SUBSCRIPTION_UPGRADE": false,
  "CUSTOM_URL_SUPPORT": "https://experienceleague.adobe.com/home#support",
  "CUSTOM_URL_VENDOR_CONSOLE": false,
  "URL_ACCOUNTS": "https://accounts-stg.magento.plat.farm",
  "URL_API_PROXY": "https://api.staging-5em2ouy-kohcf5nyfhl7k.eu-5.platformsh.site/api",
  "URL_AUTH": "https://auth.staging-5em2ouy-buxgvbf7guiee.eu-3.platformsh.site",
  "URL_GITHUB": "https://api.github.com",
  "URL_GITLAB": "https://gitlab.com",
  "URL_LOGOUT": "https://auth.staging-5em2ouy-buxgvbf7guiee.eu-3.platformsh.site/auth/logout",
  "URL_REDIRECT_AFTER_LOGIN": false,
  "URL_REDIRECT_AUTH_USER": "https://accounts-stg.magento.plat.farm/",
  "URL_SCREENSHOT_API": "https://screenshot.staging-5em2ouy-kohcf5nyfhl7k.eu-5.platformsh.site/screenshot",
  "URL_ZENDESK": false,
  "API_CLIENT_ID": "ui@magento",
  "COOKIELAW_ID": false,
  "DRIFT_CHATBOT_ID": "7prg3wvt49yk",
  "GOOGLE_TAG_MANAGER_ID": "GTM-MR3BJL",
  "HEAP_ID": false,
  "PLATFORM_TREE_ID": "7b2668ffc636f7a882ba9aa2f73d5c72bff9a062",
  "STATUS_PAGE": "l2x5n4czt1ph",
  "ZENDESK_ID": false,
  "ID_API_CLIENT": "ui@magento",
  "ID_COOKIELAW": false,
  "ID_DRIFT_CHATBOT": false,
  "ID_GOOGLE_TAG_MANAGER": "GTM-MR3BJL",
  "ID_HEAP": false,
  "ID_PLATFORM_TREE": false,
  "ID_STATUS_PAGE": "l2x5n4czt1ph",
  "ID_ZENDESK": false,
  "DISABLE_ALL_PROJECTS": false,
  "ENABLE_ACCOUNT_PAGES": true,
  "ENABLE_ACCOUNT_SECURITY_ROUTE": true,
  "ENABLE_ADD_DOMAIN_SUPPORT": true,
  "ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT": false,
  "ENABLE_ANNOUNCEMENTS": false,
  "ENABLE_AUTOMATED_BACKUPS": false,
  "ENABLE_BILLING": false,
  "ENABLE_BLACKFIRE_CARD": false,
  "ENABLE_CHANGE_USERNAME_EMAIL": false,
  "ENABLE_CONNECTED_ACCOUNTS_ROUTE": false,
  "ENABLE_CONTINUOUS_PROFILING_SELLABLE": false,
  "ENABLE_CREATE_ORGANIZATION": false,
  "ENABLE_CREATE_PROJECT": false,
  "ENABLE_CUSTOM_BANNER_MESSAGE": false,
  "ENABLE_CUSTOM_LEFT_PANEL": false,
  "ENABLE_DEPLOYMENT_OVERLAY": true,
  "ENABLE_DISCLAIMER_GENERAL_SUPPORT": false,
  "ENABLE_EDIT_PLAN": false,
  "ENABLE_ENVIRONMENT_DOMAINS": false,
  "ENABLE_ENVIRONMENT_EMAIL_SETTING": true,
  "ENABLE_ENVIRONMENT_SOCKET": true,
  "ENABLE_EXTERNAL_URL": false,
  "ENABLE_FIRST_PROJECT_INCENTIVE": false,
  "ENABLE_FREE_TRIAL_DIALOG": false,
  "ENABLE_GREEN_DISCOUNT": false,
  "ENABLE_HIPAA_COMPLIANCE": true,
  "ENABLE_HTTP_METRICS": false,
  "ENABLE_LOCAL_DEPLOYMENT_TARGET": true,
  "ENABLE_MARKETING_CHECKBOX": false,
  "ENABLE_MIGRATION_FLOW": false,
  "ENABLE_NOTIFICATION_SETTINGS": false,
  "ENABLE_OBSERVABILITY_SANDBOX": false,
  "ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS": false,
  "ENABLE_ORGANIZATION": false,
  "ENABLE_ORGANIZATION_FIRST_ONBOARDING": false,
  "ENABLE_ORGANIZATION_PLANS_PERMISSION": true,
  "ENABLE_PROFILING": false,
  "ENABLE_PROJECT_ACCESS_SELECT": false,
  "ENABLE_PROJECT_PROVISIONING_WIZARD": true,
  "ENABLE_PROJECT_SETUP_TIPS": false,
  "ENABLE_PROJECT_SETUP_WIZARD": false,
  "ENABLE_REACTIVATE_TRIAL": false,
  "ENABLE_REGISTRATION": false,
  "ENABLE_RESET_PROFILE_PASSWORD": false,
  "ENABLE_RESOURCE_METRICS_THRESHOLD": true,
  "ENABLE_SENTRY": false,
  "ENABLE_SERVICE_TREE": false,
  "ENABLE_SINGLE_STEP_SINGUP_FLOW": false,
  "ENABLE_SOURCE_OPERATION": true,
  "ENABLE_SOURCE_REPOSITORY_TOOLTIP": true,
  "ENABLE_SSO": true,
  "ENABLE_STAFF_TEST_MODE": false,
  "ENABLE_STRIPE": false,
  "ENABLE_SUBSCRIBE_FLOW": false,
  "ENABLE_SUPPORT_SELLABLE": false,
  "ENABLE_TERMS_AND_ACCEPTANCE_CHECKBOX": false,
  "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS": false,
  "ENABLE_UNBRANDED_ILLUSTRATIONS": true,
  "ENABLE_USAGE_BASED_BILLING_PAGES": false,
  "ENABLE_USER_MANAGEMENT": true,
  "ENABLE_VOUCHERS": false,
  "ENABLE_ZENDESK": true,
  "GA_PROPERTY_ID": false,
  "VERSION_NUMBER": "3.2.54"
}