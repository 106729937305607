const en = {
  "url.logo_url": "https://business.adobe.com/products/magento/magento-commerce.html",
  "footer.message": "Version {appVersion} Powered by Platform.sh",
  "consent_description": "Authorize Adobe to use your account",
  "app_name": "Adobe Commerce Cloud",
  "CLI_command_name": "magento-cloud",
  "links.CLI_repository": "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/cloud-cli.html",
  "settings.variables.during_build": "<b>Available during buildtime:</b> Make this variable available during the build hook step of your <code>.magento.app.yaml</code>",
  "services.noconfiguration": "Console does not currently output the content of sub-directory <code>.magento.app.yaml</code> files. To see your complete app configuration in Console, move the content of your <code>.magento.app.yaml</code> files to <code>.magento/applications.yaml</code>.",
  "app_environment_variable_name": "$MAGENTO_VARIABLES",
  "configuration_folder": ".magento",
  "app_config_file": ".magento.app.yaml",
  "app_service_file_path": ".magento/service.yaml",
  "app_services_file_path": ".magento/services.yaml",
  "app_routes_file_path": ".magento/routes.yaml",
  "app_applications_file_path": ".magento/applications.yaml",
  "delete_branch_link": "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/cli-branches.html?lang=en#delete-an-environment",
  "settings.domains.add.body": "By default, your environment has a <i>.magentosite.cloud</i> URL. If you want to change the web address, you need to add a custom domain, such as <i>example.com.</i>.",
  "no_access_environment_decativate_local_target": "Reach out to Support to deactivate your environment"
}

export default en;