const links = {
  "links.documentation":
    "https://experienceleague.adobe.com/docs/commerce.html",
  "links.documentation.access":
    "https://experienceleague.adobe.com/docs/commerce.html",
  "links.documentation.configure_project.certificates":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/cdn/setup-fastly/fastly-configuration.html#provision-ssl%2Ftls-certificates",
  "links.documentation.domain":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/start/new-project.html",
  "links.documentation.deploy_key":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/project/console-branches.html#find-your-deploy-key",
  "links.documentation.integrations":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/integrations/overview.html?lang=en",
  "links.documentation.app.variables":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/env/stage/variables-intro.html",
  "links.documentation.users":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/project/user-access.html",
  "links.documentation.variables.run_and_build_time":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/env/variable-levels.html",
  "links.documentation.variables":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/env/variable-levels.html",
  "links.documentation.inactive_environment":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html",
  "links.documentation.WCAG":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html",
  "links.documentation.getting_started_CLI":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/cloud-cli.html?lang=en",
  "links.documentation.routes_SSI":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/routes/server-side-includes.html",
  "links.documentation.live_backups":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/storage/snapshots.html",
  "links.documentation.SSH":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/secure-connections.html",
  "links.documentation.SSH_keys":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/secure-connections.html",
  "links.documentation.dedicated_cluster":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/architecture/pro-architecture.html",
  "links.documentation.CLI_API_token":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/cloud-cli.html",
  "links.documentation.app":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/app/configure-app-yaml.html",
  "links.documentation.routes":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/routes/routes-yaml.html",
  "links.documentation.integrations.source":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/integrations/overview.html?lang=en",
  "links.documentation.integrations.activity":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/integrations/overview.html?lang=en",
  "links.documentation.integrations.activity_scripts":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/dev-tools/integrations/overview.html?lang=en",
  "links.documentation.administration.servers": "",
  "links.documentation.automated_updates":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html",
  "links.documentation.source_operations":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html",
  "links.documentation.admin_backups":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/storage/snapshots.html?lang=en",
  "links.documentation.environment_search_engine_visibility":
    "https://experienceleague.adobe.com/",
};

export default links;
